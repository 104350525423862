<div class="content-padding">
  <div class="restaurants">
    <div class="restaurant" *ngFor="let r of restaurants">
      <app-fold-item [title]="r.name" [isOpen]="restaurant === r" size="big" content="restaurant"
        (triggerItem)="selectRestaurant(r)"></app-fold-item>
      <div class="additional-restaurant-info" *ngIf="restaurant === r">
        <div class="open-hours">
          <p class="title">{{ 'OPEN_HOURS' | translate }}</p>
          <!--<div *ngIf="r.name === 'Laax'" style="font-weight: bold; color: #641B65;">{{ 'ONLY_TAKEOUT' | translate }}</div>-->
          <div class="details">
            <div class="regular" *ngFor="let o of restaurantOpenHours('regular')">
              <div class="week-day">
                {{ 'WEEK_DAY' + o.fromDay | translate}}<span *ngIf="o.fromDay !== o.toDay"> -
                  {{ 'WEEK_DAY' + o.toDay | translate}}</span>:
              </div>
              <div class="times">
                <div class="time" *ngFor="let t of o.times">
                  <span *ngIf="t.openFrom !== t.openTo">
                    {{formatTime(t.openFrom)}} - {{formatTime(t.openTo)}} <span class="timeWord">{{'O_CLOCK' |
                      translate}}</span>
                  </span>
                  <span class="closed" *ngIf="t.openFrom === t.openTo">
                    {{'CLOSED' | translate}}
                  </span>
                </div>

              </div>
            </div>
            <div class="special" *ngFor="let o of restaurantOpenHours('special'); index as i"
              [style.marginTop]="i === 0 ? '8px' : 0">
              <div class="week-day">
                {{o.specialDate | date:'dd.MM.yyyy'}}:
              </div>
              <span class="open times" *ngIf="o.openFrom !== o.openTo">
                {{formatTime(o.openFrom)}} - {{formatTime(o.openTo)}} <span class="timeWord">{{'O_CLOCK' |
                  translate}}</span>
              </span>
              <span class="closed times" *ngIf="o.openFrom === o.openTo">
                {{'CLOSED' | translate}}
              </span>
            </div>
            <div class="no-open-hours" *ngIf="restaurantOpenHours('regular')?.length < 1">Restaurant has no regular Open
              Hours</div>
          </div>
          <div class="note" *ngIf="r.name !== 'Laax'">{{'OPEN_HOURS_NOTE' | translate}}</div>
          <div class="note" *ngIf="r.name === 'Laax'">{{'LAAX_OPEN_HOURS_NOTE' | translate}}</div>
        </div>
        <div class="address">
          <div class="title">{{ r.name }}</div>
          <div class="details">
            <p (click)="openGoogleMaps(r.latitude, r.longitude)" class="multi-line-txt street-city"
              [innerHTML]="r.address | replaceLineBreaks"></p>
            <a href="tel:{{formatedPhoneNumber}}" class="email">
              <div>{{r.phoneNumber ? r.phoneNumber : 'PHONENUMBER IS NULL'}}</div>
            </a>
            <!--<a *ngIf="r.restaurantId === 2" href="https://mytools.aleno.me/reservations/v2.0/reservations.html?k=eyJrIjoid2l2dTVrM2lsNm15cnBiOWlwdzZ4bmViajhycnVkaWRpZ280bGZwODBsbzlhNGlweTEiLCJyIjoiTkxLRHN6OWVLNnVzUU5yR1ciLCJzIjoiaHR0cHM6Ly9teXRvb2xzLmFsZW5vLm1lLyJ9" class="email">{{ 'RESERVATION_TOOL_LAAX' | translate }}</a>-->
            <div (click)="r.email && openEmail(r.email)" class="email">{{r.email ? r.email : 'EMAIL IS NULL'}}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- andermatt -->
    <div class="restaurant">
      <app-fold-item [title]="newRestaurant.name" [isOpen]="restaurant && restaurant.restaurantId === 10" size="big" content=""
        (triggerItem)="selectRestaurant(newRestaurant)"></app-fold-item>
      <div class="additional-restaurant-info" *ngIf=" restaurant && restaurant.restaurantId === 10">
        <div class="open-hours">
          <p class="title">{{"NEW_OPENING_TITLE" | translate}}</p>
          <div class="note">{{"NEW_OPENING_TEXT" | translate}}</div>
        </div>
        <div class="address">
          <div class="title">{{ newRestaurant.name }}</div>
          <div class="details">
            <p (click)="openGoogleMaps(newRestaurant.latitude, newRestaurant.longitude)" class="multi-line-txt street-city"
              [innerHTML]="newRestaurant.address | replaceLineBreaks"></p>
            <!-- <a href="tel:{{formatedPhoneNumber}}" class="email">
              <div>{{r.phoneNumber ? r.phoneNumber : 'PHONENUMBER IS NULL'}}</div>
            </a> -->
            <!--<a *ngIf="r.restaurantId === 2" href="https://mytools.aleno.me/reservations/v2.0/reservations.html?k=eyJrIjoid2l2dTVrM2lsNm15cnBiOWlwdzZ4bmViajhycnVkaWRpZ280bGZwODBsbzlhNGlweTEiLCJyIjoiTkxLRHN6OWVLNnVzUU5yR1ciLCJzIjoiaHR0cHM6Ly9teXRvb2xzLmFsZW5vLm1lLyJ9" class="email">{{ 'RESERVATION_TOOL_LAAX' | translate }}</a>-->
            <!-- <div (click)="r.email && openEmail(r.email)" class="email">{{r.email ? r.email : 'EMAIL IS NULL'}}</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>