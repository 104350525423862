// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`...

export const environment = {
    production: false,
    api_url: 'https://noobadevv3.azurewebsites.net/',
    //api_url: 'https://yoodevov3.azurewebsites.net/',
    // api_url: 'https://noobav3.azurewebsites.net/',
    legacy_api_url: 'https://noobadevv2.azurewebsites.net/',
    majorVersion: '0',
    minorVersion: '0',
    commitNumber: '176'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
